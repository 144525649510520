<template>
  <div class="page_container">
    <div class="content_breadcrumb">
      <div class="content_nav">
        当前位置&nbsp;： 新闻资讯 &nbsp;&nbsp; >&nbsp;&nbsp;
        <span class="content_detail">详细内容</span>
      </div>
    </div>
    <div class="news_content container">
        <div class="left">
          <div class="tit">
            <h1>江苏东交智控科技集团股份有限公司与秉匠科技签订“黑洞”图形引擎采购合同</h1>
            <div class="time">发布时间：2020-8-10 17:20</div>
            <div class="info">
              <p class="p_pic">
                <span style="font-size: 14px;"><img src="../../assets/image/news/news_dongjiao01.jpg"></span>
              </p>
              <p class="p_pic">
                <span style="font-size: 14px;"><img src="../../assets/image/news/news_dongjiao02.jpg"></span>
              </p>
              <p>近日，江苏东交智控科技集团股份有限公司（以下简称东交智控）与上海秉匠信息科技有限公司（以下简称秉匠科技）正式签订“黑洞”BIM+GIS 三维图形引擎采购合同。</p>
              <p  class="p_pic">
                <span style="font-size: 14px;"><img src="../../assets/image/news/news_dongjiao03.jpg"></span>
              </p>
              <p>“黑洞”引擎将为东交智控的数字化管理平台提供多源异构数据集成、三维可视化、物联数据集成等功能，助力东交智控完善其在道路工程各环节中管理控制信息化、可视化、快速化、一体化能力，补齐模型数据整合、信息集成、隐蔽工程监管、数据追溯等管理抓手，进一步提升东交智控在道路基础设施领域的数字化核心竞争力。</p>
              <p  class="p_pic">
                <span style="font-size: 14px;"><img src="../../assets/image/news/news_dongjiao04.jpg"></span>
              </p>
              <p  class="p_pic">
                <span style="font-size: 14px;"><img src="../../assets/image/news/news_dongjiao05.jpg"></span>
              </p>
              <p  class="p_pic">
                <span style="font-size: 14px;"><img src="../../assets/image/news/news_dongjiao06.jpg"></span>
              </p>
              <p style="text-indent:0">相关介绍</p>
              <p style="text-align:center">东交智控</p>
              <p>东交智控是一家专注服务于公路、水运、市政、轨道工程及互联网等领域的高新技术企业，业务涵盖智慧品控、质量管理、检测服务、规划设计、创新研究、环保安全等多方面，客户覆盖全国20多个省份。东交智控拥有一支技术力量强、经验丰富的技术团队，并结合对工程行业和信息化行业的深入理解，解决了工程技术与互联网技术的交叉学科技术难题，率先将工程技术咨询与信息化技术应用深度融合。</p>
            </div>
          </div>
        </div>
        <div class="right">
          <div class="tit"><h3><font></font>热点内容</h3></div>
          <ul>
            <li v-for="item in hotList" :key="item.id">
              <p><router-link :to="item.link">{{item.name}}</router-link></p>
              <span>{{item.time}}</span>
            </li>
          </ul>
        </div>
      </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      hotList: [
        {id:1,name: '“黑洞”图形引擎助力中电建华东院打造智慧城市CIM平台',time: '2020-11-09',link:'/newsDetails_new_CIM'},
        {id:2,name: '秉匠科技又双叒叕获奖了！',time: '2020-10-28',link:'/newsDetails_new_BIM'},
        {id:3,name: '江苏东交智控科技集团股份有限公司与秉匠科技签订“黑洞”图形引擎采购合同',time: '2020-11-02',link:'/newsDetails_new_dongjiao'},
        {id:4,name: '“黑洞引擎”荣获“上海市第二届BIM技术应用创新大赛”特别创意优秀奖',time: '2020-8-10',link:'/newsDetails_new_hj'}
      ]
    }
  },
  mounted () {
      document.querySelector('.nav').style.backgroundColor = '#000'
  },
  destroyed () {
      document.querySelector('.nav').style.backgroundColor = 'transparent'
  }
}
</script>

<style lang="less" scoped>
.page_container{
  margin-top: 50px;
  .news_content{
    overflow: hidden;
    .left{
      width: 60%;
      height: 100%;
      .tit{
        h1{
          color: #222;
          font-size: 22px;
          font-weight: normal;
          line-height: 30px;
        }
        .time{
          margin: 10px;
          height: 27px;
          border-bottom: 1px #ddd solid;
          font-size: 14px;
          color: #999;
          padding-right: 30px;
        }
      }
      .info{
        padding-top: 32px;
        text-align: center;
        p{
          font-size: 16px;
          color: #222;
          line-height: 28px;
          text-align: left;
          text-indent:2em;
        }
        .p_pic{
          text-indent: 0;
        }
        img{
          margin: 16px 0;
        }
      }
    }
    .right{
      width: 35%;
      height: 100%;
      .tit{
        border-bottom: 1px #444 solid;
        padding-bottom: 14px;
        margin-top: 24px;
        font{
          width: 4px;
          height: 18px;
          background: #222;
          display: inline-block;
          margin-right: 16px;
          position: relative;
          top: 4px;
        }
      }
      ul{
        padding: 12px;
        li{
          line-height: 24px;
          font-size: 16px;
          color: #555;
          border-bottom: 1px #ddd solid;
          padding: 12px 0;
          p{
            a{
              color: #555;
            }
          }
          span{
            display: block;
            font-size: 14px;
            color: #777;
            padding-top: 4px;
          }
        }
        .router-link-active{
          border-bottom: none;
        }
      }
    }
  }
}
</style>

